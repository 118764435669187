const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

export default {
    auth : {
        login  : baseUrl + 'user/login/',
        logout : baseUrl + 'user/logout/',
        status : baseUrl + 'user/status/'
    },
    registration : 'https://www.serbismmgu.org/api/registrationAction.php',
    contactUs    : 'https://www.serbismmgu.org/api/contactAction.php'
};
