<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-light shadow-sm p-3">
            <div @click="$router.push(titleLink)" class="cursor-pointer">
                <slot name="logo"></slot>
                <span class="navbar-brand project-name">
                    {{ title }}
                </span>
            </div>

            <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                <ul v-if="appNav" class="navbar-nav">
                    <li @click="logout" class="nav-item cursor-pointer">
                        <span class="nav-link text-primary-dark" href="#"><i class="fa fa-power-off"/></span>
                    </li>
                    <li class="nav-item cursor-pointer">
                        <span v-if="currentUser.name" class="nav-link text-primary-dark" href="#"><i
                            class="fa fa-user-circle-o"/> {{ currentUser.name }}</span>
                        <span v-else class="nav-link text-primary-dark" href="#"><i
                            class="fa fa-user-circle-o"/> User</span>
                    </li>
                </ul>
                <ul v-else class="navbar-nav">
                    <li @click="websiteLogout" v-if="currentUser.loggedIn" class="nav-item cursor-pointer m-1">
                        <span class="btn btn-primary text-capitalize">
                            Welcome, {{ currentUser.username }}
                            <span class="p-1" href="#">
                                <i class="fa fa-power-off"/>
                            </span>
                        </span>
                    </li>
                    <li v-else class="nav-item cursor-pointer" @click="$router.push('/auth/web/login/')">
                        <span class="btn btn-outline-primary">LOGIN / SIGNUP</span>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>
<script>
import axios from 'axios';
import urls from '@/data/urls';
import { mapGetters } from 'vuex';

export default {
    name  : 'top-nav-bar',
    props : {
        appNav : {
            type    : Boolean,
            default : false
        },
        logo : {
            type : String
        },
        titleLink : {
            type    : String,
            default : '#'
        },
        title : {
            type    : String,
            default : 'Awescripts'
        }
    },
    data () {
        return {
            data : ''
        };
    },
    computed : {
        ...mapGetters(['currentUser'])
    },
    methods : {
        async logout () {
            const response = await axios.post(urls.auth.logout);
            const json = response.data;
            if (json && json.success === true) {
                alert(json.message);
                this.$router.push('/auth/login/');
            }
        },
        async websiteLogout () {
            const response = await axios.post(urls.auth.logout);
            const json = response.data;
            if (json && json.success === true) {
                alert(json.message);
                this.$router.push('/web/');
                this.$router.go();
            }
        }
    }
};
</script>

<style scoped>

</style>
