<template>
    <b-modal class="mt-5" id="modal" :title="title" v-model="modalVisible" :hide-footer="hideFooter"
             :header-bg-variant="headerColor" :header-text-variant="headerTextColor"
             @show="setModalStyles" :dialog-class="modalWidthClass" @ok="okClicked" :ok-variant="okButtonColor">
        <slot></slot>
        <div class="text-end mt-3">
            <slot name="buttons"></slot>
        </div>
    </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';

export default {
    name       : 'modal',
    components : {
        BModal
    },
    props : {
        title           : { type : String, default : 'Title' },
        width           : { type : String, default : 'md' },
        hideFooter      : { type : Boolean, default : true },
        headerColor     : { type : String, default : '' },
        okButtonColor   : { type : String, default : 'primary' },
        headerTextColor : { type : String, default : 'dark' }
    },
    data () {
        return {
            modalVisible    : false,
            modalWidthClass : ''
        };
    },
    mounted () {
        this.setModalWidth(this.width);
    },
    methods : {
        okClicked () {
            this.$emit('okClicked');
        },
        show () {
            this.modalVisible = true;
        },
        close () {
            this.modalVisible = false;
        },
        setModalStyles () {
            const modal = document.querySelector('.modal-dialog');
            if (modal) {
                modal.classList.add('modal-dialog-centered');
            }
        },
        setModalWidth (width) {
            this.modalWidthClass = width === 'lg' ? 'modal-lg' : width === 'sm' ? 'modal-sm' : '';
        }
    }
};
</script>

<style>
.modal-dialog-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.modal-header .close {
    color: #000000;
    font-size: 1.5rem;
    opacity: 0.8;
    border: none;
    background: none;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.modal-header .close:hover {
    opacity: 1;
}
</style>
