<template>
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <form role="form" @submit.prevent="handleSubmit(submitClicked)">
            <slot :model="model" :loading="loading"></slot>
        </form>
    </validation-observer>
</template>
<script>
import axios from 'axios';

export default {
    name  : 'c-form',
    props : {
        initialData : { type : Object, required : false },
        saveUrl     : { type : String, required : false },
        saveParams  : { type : Object, required : false }
    },
    data () {
        return {
            model   : {},
            loading : false
        };
    },
    created () {
        this.setInitialData();
    },
    methods : {
        setModelValue (key, value) {
            this.$set(this.model, key, value);
        },
        getModelValue (key) {
            return this.model[key];
        },
        setInitialData () {
            const initialData = this.initialData;
            if (initialData) {
                this.model = {
                    ...initialData
                };
            }
        },
        submitClicked () {
            const that = this;
            this.loading = true;
            const data = this.setFormData();
            const url = this.saveUrl;
            if (!url) {
                this.onSubmitSuccess(data);
            } else {
                axios.post(url, data, {
                    headers : {
                        'Content-Type' : 'multipart/form-data'
                    }
                }).then(function (response) {
                    that.onSubmitSuccess(response);
                }).catch(function () {
                    that.onSubmitFailed();
                });
            }
        },
        setFormData () {
            let saveParams = this.saveParams;
            if (!saveParams) {
                saveParams = {};
            }
            const formData = new FormData();
            for (const key in this.model) {
                if (Object.prototype.hasOwnProperty.call(this.model, key)) {
                    formData.append(key, this.model[key]);
                }
            }
            for (const key in saveParams) {
                if (Object.prototype.hasOwnProperty.call(saveParams, key)) {
                    formData.append(key, saveParams[key]);
                }
            }
            return formData;
        },
        onSubmitSuccess (response) {
            this.loading = false;
            const json = response.data;
            try {
                if (json.success === true) {
                    this.$emit('formSuccess', response);
                } else {
                    this.$emit('formFailed', response);
                    const formValidator = this.$refs.formValidator;
                    if (json.errors) {
                        formValidator.setErrors(json.errors);
                    }
                }
            } catch (e) {
                console.log('error', e);
            }
        },
        onSubmitFailed () {
            this.loading = false;
            alert('Some errors occurred while communicating with the server. Please try again later.');
            this.$emit('error');
        }
    }
};
</script>
