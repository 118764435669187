<template>
    <div class="flex-shrink-0 pt-2 bg-white full-height">
        <ul class="list-unstyled ps-0">
            <li v-for="(item, index) in menu" :key="index" :class="getItemClasses(item, index)">
                <div>
                    <div class="row align-items-center rounded collapsed">
                        <div :class="getIconClasses(item) + ' col-10 p-2'">
                            <span class="p-lg-3 nav-item-text cursor-pointer"
                                  @click="$router.push(item.link)">{{ item.text }}</span>
                        </div>
                        <div v-if="hasSubItems(item)" class="col-2"
                             @click="toggleSubmenu(item, index)">
                            <span :class="getToggleIconClasses(index)" aria-hidden="true"></span>
                        </div>
                    </div>
                </div>
                <template v-if="hasSubItems(item)">
                    <div v-for="(subItem, subItemIndex) in item.items" :key="subItemIndex"
                         :class="getSubItemClasses(index, subItemIndex)">
                        <div @click="$router.push(subItem.link)" style="padding-left: 1.5rem; padding-top: 1rem;"
                             class="cursor-pointer">
              <span :class="getIconClasses(subItem)">
                <span class="p-lg-2 nav-sub-item-text">{{ subItem.text }}</span>
              </span>
                        </div>
                    </div>
                </template>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name  : 'SideNavBar',
    props : {
        menu : {
            type    : Array,
            default : null
        }
    },
    data () {
        return {
            menuSelected     : '',
            submenuSelected  : '',
            expandedItems    : [],
            expandedSubmenus : []
        };
    },
    methods : {
        getItemClasses (item, index) {
            return [
                'p-lg-2',
                'border-bottom',
                'nav-item-hover',
                {
                    'nav-item-selected' : this.menuSelected === index || this.isItemExpanded(index)
                }
            ];
        },
        isItemExpanded (index) {
            return this.expandedItems.includes(index);
        },
        getToggleIconClasses (index) {
            return {
                'fa fa-chevron-down' : !this.isItemExpanded(index) || !this.hasSubItems(this.menu[index]),
                'fa fa-chevron-up'   : this.isItemExpanded(index) && this.hasSubItems(this.menu[index])
            };
        },
        getIconClasses (item) {
            return item.icon ? item.icon : 'fa fa-circle-o-notch';
        },
        toggleSubmenu (item, index) {
            if (this.isItemExpanded(index)) {
                this.expandedItems = this.expandedItems.filter(i => i !== index);
            } else {
                this.expandedItems.push(index);
            }
            if (this.isSubmenuExpanded(index)) {
                this.expandedSubmenus = this.expandedSubmenus.filter(i => i !== index);
            } else {
                this.expandedSubmenus.push(index);
            }
        },
        isSubmenuExpanded (index) {
            return this.expandedSubmenus.includes(index);
        },
        getSubItemClasses (index, subItemIndex) {
            return [
                'collapse',
                {
                    show : this.isSubmenuExpanded(index)
                }
            ];
        },
        hasSubItems (item) {
            return item.items && item.items.length !== 0;
        }
    }
};
</script>

<style scoped>
</style>
