<template>
    <validation-provider v-bind="$attrs" v-slot="{ errors }"
                         :name="validatorName" :rules="rules">
        <label v-if="label" v-html="label" :for="generatedUUID"/>

        <div class="c-form-control p-1">
            <div>
                <slot name="prepend"></slot>
            </div>
            <input :id="generatedUUID" :required="required" class="m-1" :placeholder="placeholder" :pattern="pattern"
                   :type="type" :value="value" :name="name" :disabled="disabled" :min="min"
                   @input="$emit('input', $event.target.value)" @change="onChange"
                   @blur="onBlur" @focus="onFocus">
            <div>
                <slot name="append"></slot>
            </div>
        </div>
        <p class="text-danger" v-html="errors[0]" v-if="errors[0]"></p>
    </validation-provider>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    name  : 'validated-input',
    props : {
        min : {
            type    : [String, Object, Array],
            default : null
        },
        name : {
            type    : String,
            default : ''
        },
        label : {
            type    : String,
            default : ''
        },
        placeholder : {
            type    : String,
            default : ''
        },
        pattern : {
            type    : String,
            default : null
        },
        type : {
            type    : String,
            default : 'text'
        },
        value : {
            type     : [String, Number],
            default  : '',
            required : true
        },
        rules : {
            type    : Object,
            default : () => ({})
        },
        required : {
            type    : Boolean,
            default : false
        },
        disabled : {
            type    : Boolean,
            default : false
        },
        appendIcon : {
            type        : String,
            description : 'Append icon (right)'
        },
        prependIcon : {
            type        : String,
            description : 'Prepend icon (left)'
        }
    },
    data () {
        return {
            validatorName : '',
            generatedUUID : ''
        };
    },
    created () {
        this.validatorName = this.name || this.label || this.placeholder;
    },
    mounted () {
        this.generateUUID();
    },
    methods : {
        generateUUID () {
            this.generatedUUID = uuidv4();
        },
        onChange () {
            this.$emit('change');
        },
        onBlur () {
            this.$emit('blur');
        },
        onFocus () {
            this.$emit('focus');
        }
    }
};
</script>
