<template>
    <validation-provider v-bind="$attrs" v-slot="{ errors }"
                         :name="validatorName" :rules="rules">
        <label v-if="label" v-html="label" :for="generatedUUID"/>

        <div class="c-form-control p-1">
            <div>
                <slot name="prepend"></slot>
            </div>
            <input :id="generatedUUID" :required="required" class="m-1"
                   :name="name" :disabled="disabled" :accept="accept"
                   type="file" @change="handleFileInput($event)"/>
            <div>
                <slot name="append"></slot>
            </div>
        </div>
        <p class="text-danger" v-html="errors[0]" v-if="errors[0]"></p>
    </validation-provider>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    name  : 'validated-input',
    props : {
        name : {
            type    : String,
            default : ''
        },
        label : {
            type    : String,
            default : ''
        },
        accept : {
            type    : String,
            default : ''
        },
        value : {
            type     : [String, File],
            default  : '',
            required : true
        },
        rules : {
            type    : Object,
            default : () => ({})
        },
        required : {
            type    : Boolean,
            default : false
        },
        disabled : {
            type    : Boolean,
            default : false
        },
        appendIcon : {
            type        : String,
            description : 'Append icon (right)'
        },
        prependIcon : {
            type        : String,
            description : 'Prepend icon (left)'
        }
    },
    data () {
        return {
            validatorName : '',
            generatedUUID : ''
        };
    },
    created () {
        this.validatorName = this.name || this.label;
    },
    mounted () {
        this.generateUUID();
    },
    methods : {
        generateUUID () {
            this.generatedUUID = uuidv4();
        },
        handleFileInput (event) {
            const file = event.target.files[0];
            if (file) {
                this.$emit('input', file);
            }
        }
    }
};
</script>
