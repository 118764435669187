<template>
    <div class="container card p-3">
        <div class="row">
            <h4 class="col-3 mb-3">{{ title }}</h4>
            <div class="col-9 text-end">
                <slot name="buttons"></slot>
            </div>
        </div>
        <table class="table table-borderless">
            <thead>
            <tr v-if="fields.length!==0" class="border">
                <th v-for="(field, index) in fields" :key="'th' + index">
                    {{ field.title }}
                </th>
            </tr>
            </thead>
            <tbody>
            <template v-if="data.length!==0 && fields.length!==0">
                <tr class="border" v-for="(item, index) in data" :key="'tr' + index">
                    <td v-for="(field, index) in fields" :key="'td' + index">
                        <template v-if="isSlotField(field)">
                            <slot :name="field.name" v-bind:rowData="item"></slot>
                        </template>
                        <template v-else>
                            {{ item[field.name] }}
                        </template>
                    </td>
                </tr>
            </template>
            <tr v-if="data.length===0" class="border">
                <td colspan="12" class="text-center">No data available.</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name  : 'simple-table',
    props : {
        title  : { type : String, default : '' },
        fields : { type : Array, default : null, required : true },
        data   : { type : Array, default : null, required : true }
    },
    methods : {
        isSlotField (field) {
            return field.type === 'slot';
        }
    }
};
</script>

<style scoped>
.container {
    margin-top: 20px;
}
</style>
