import guards from '@/router/guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/WebLayout');

// Website Components
const LandingPage = () => import(/* webpackChunkName: "home" */'../views/Website/LandingPage');
const WebHome = () => import(/* webpackChunkName: "home" */'../views/Website/WebsiteHome');
const WebRegister = () => import(/* webpackChunkName: "home" */'../views/Website/Registration');

export default {
    path        : '/',
    name        : 'AppLayout',
    component   : Layout,
    beforeEnter : guards.statusGuard,
    children    : [
        // Website Pages
        {
            path      : '/',
            name      : 'LandingPage',
            component : LandingPage
        },
        // Website Pages
        {
            path      : '/web/',
            name      : 'WebHome',
            component : WebHome
        },
        {
            path      : '/web/register/',
            name      : 'WebRegister',
            component : WebRegister
        }
    ]
};
