<template>
    <validation-provider v-bind="$attrs" v-slot="{ errors }"
                         :name="validatorName" :rules="rules">
        <label v-if="label" v-html="label" :for="generatedUUID"/>

        <div class="c-form-control p-1">
            <div>
                <slot name="prepend"></slot>
            </div>
            <select :id="generatedUUID" :required="required" class="form-select m-1"
                    :value="value" :name="name" :disabled="disabled"
                    @input="$emit('input', $event.target.value)" @change="onChange"
                    @blur="onBlur" @focus="onFocus">
                <option v-if="placeholder" selected value="">{{ placeholder }}</option>
                <option v-else selected value="">Click to choose {{ name || label }}</option>
                <option v-for="(option, index) in options" :value="option.value" :key="index">
                    {{ option.label }}
                </option>
                <option v-if="options.length===0" selected value="">
                    No {{ name || label }} options available.
                </option>
            </select>
            <div>
                <slot name="append"></slot>
            </div>
        </div>
        <p class="text-danger" v-html="errors[0]" v-if="errors[0]"></p>
    </validation-provider>
</template>

<script>
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export default {
    name  : 'validated-ajax-select',
    props : {
        name : {
            type    : String,
            default : ''
        },
        placeholder : {
            type    : String,
            default : ''
        },
        label : {
            type    : String,
            default : ''
        },
        value : {
            type     : [String, Number],
            default  : '',
            required : true
        },
        rules : {
            type    : Object,
            default : () => ({})
        },
        required : {
            type    : Boolean,
            default : false
        },
        disabled : {
            type    : Boolean,
            default : false
        },
        url : {
            type     : String,
            required : true
        }
    },
    data () {
        return {
            selectedValue : '',
            validatorName : '',
            options       : [],
            generatedUUID : ''
        };
    },
    created () {
        this.validatorName = this.name || this.label;
    },
    mounted () {
        this.fetchOptions();
        this.generateUUID();
    },
    methods : {
        generateUUID () {
            this.generatedUUID = uuidv4();
        },
        onChange () {
            this.$emit('change');
        },
        onBlur () {
            this.$emit('blur');
        },
        onFocus () {
            this.$emit('focus');
        },
        onOptionsLoaded (selectedValue) {
            this.selectedValue = selectedValue;
        },
        fetchOptions () {
            axios.get(this.url)
                .then(response => {
                    this.options = { ...response.data.data };
                })
                .catch(error => {
                    console.error('Failed to fetch options:', error);
                });
        }
    }
};
</script>
<style>
.c-form-control {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
}

.c-form-control ::slotted(*) {
    margin: 0 4px;
}
</style>
