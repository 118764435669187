import store from '@/store';
import axios from 'axios';
import urls from '../data/urls';

const checkStatus = function (fn) {
    if (store.getters.checkedStatus === true || store.getters.needChecking === false) {
        return fn();
    }

    axios.get(urls.auth.status).then(function (response) {
        const json = response.data;
        if (json.data && json.data.loggedIn) {
            store.commit('setUser', json.data);
        } else {
            store.commit('setLogout');
        }

        const sPublicKey = json['public-key'];
        if (sPublicKey) {
            store.commit('setPublicKey', sPublicKey);
        }
        return fn();
    }).catch(function () {
        store.commit('setLogout');
        return fn();
    });
};

export default {
    statusGuard : function (to, from, next) {
        checkStatus(function () {
            console.log('next', to);
            next();
        });
    },

    loggedInGuard : function (to, from, next) {
        if (store.getters.needChecking === false) {
            next();
        } else {
            checkStatus(function () {
                const currentUser = store.getters.currentUser;
                if (!currentUser.loggedIn) {
                    next('/auth/login/');
                } else {
                    if (currentUser.is_superuser) {
                        next();
                    } else {
                        axios.post(urls.auth.logout).then(function (response) {
                            store.commit('setLogout');
                            next('/auth/login/');
                        }).catch(function () {
                            store.commit('setLogout');
                            next('/auth/login/');
                        });
                    }
                }
            });
        }
    }
};
