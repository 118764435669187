<template>
    <div class="container card p-3">
        <div class="row">
            <h4 class="col-3 mb-3">{{ title }}</h4>
            <div class="col-9 text-end">
                <slot name="buttons"></slot>
            </div>
        </div>
        <table class="table table-borderless">
            <thead>
            <tr v-if="fields.length!==0" class="border">
                <th v-for="(field, index) in fields" :key="'th' + index">
                    {{ field.title }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="dataLoading" class="border">
                <td colspan="12" class="text-center">
                    <loading-animation size="xs"/>
                </td>
            </tr>
            <template v-if="!dataLoading && data.length!==0 && fields.length!==0">
                <tr class="border" v-for="(item, index) in data" :key="'tr' + index">
                    <td v-for="(field, index) in fields" :key="'td' + index">
                        <template v-if="isSlotField(field)">
                            <slot :name="field.name" v-bind:rowData="item"></slot>
                        </template>
                        <template v-else>
                            {{ item[field.name] }}
                        </template>
                    </td>
                </tr>
            </template>
            <tr v-if="!dataLoading && data.length===0" class="border">
                <td colspan="12" class="text-center">No data available.</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name  : 'vue-table',
    props : {
        title : { type : String, default : '' },
        url   : {
            type     : String,
            required : true
        },
        fields : {
            type     : Array,
            required : true
        },
        extraParams : {
            type    : Object,
            default : () => ({})
        }
    },
    data () {
        return {
            dataLoading : false,
            data        : []
        };
    },
    mounted () {
        this.fetchData();
    },
    watch : {
        extraParams : {
            immediate : true,
            deep      : true,
            handler () {
                this.refreshTable();
            }
        }
    },
    methods : {
        isSlotField (field) {
            return field.type === 'slot';
        },
        refreshTable () {
            this.fetchData();
        },
        async fetchData () {
            this.dataLoading = true;
            const response = await axios.get(this.url, { params : this.extraParams }).catch(error => {
                console.error('Error fetching table data:', error);
            });
            if (response.data.success === true) {
                this.data = response.data.data;
            }
            this.dataLoading = false;
        }
    }
};
</script>

<style scoped>
.container {
    margin-top: 20px;
}
</style>
