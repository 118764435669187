import Vue from 'vue';

// Navbar
import SideNavBar from '../components/awe-com/navbar/side-nav-bar';
import TopNavBar from '../components/awe-com/navbar/top-nav-bar';

// Tables
import SimpleTable from '../components/awe-com/custom-table/simple-table';
import VueTable from '../components/awe-com/custom-table/vue-table';

// Animations
import LoadingAnimation from '../components/awe-com/animations/LoadingAnimation';

// Modals
import Modal from '../components/awe-com/modals/modal';
import DeleteModal from '../components/awe-com/modals/delete-modal';
import ConfirmModal from '../components/awe-com/modals/confirm-modal';

// Forms
import CustomForm from '../components/awe-com/forms/c-form';

// Form Inputs
import ValidatedInput from '../components/awe-com/form-inputs/validated-input';
import ValidatedTextArea from '../components/awe-com/form-inputs/validated-text-area';
import ValidatedFileInput from '../components/awe-com/form-inputs/validated-file-input';
import ValidatedSelect from '../components/awe-com/form-inputs/validated-select';
import ValidatedAjaxSelect from '../components/awe-com/form-inputs/validated-ajax-select';
import ValidatedCheckBox from '../components/awe-com/form-inputs/validated-check-box';

// Validations
import { ValidationObserver, ValidationProvider } from 'vee-validate';

// ----------------------------------------------------------------------------------- //

// Navbar Registration
Vue.component('side-nav-bar', SideNavBar);
Vue.component('side-nav-bar', SideNavBar);
Vue.component('top-nav-bar', TopNavBar);

// Tables Registration
Vue.component('simple-table', SimpleTable);
Vue.component('vue-table', VueTable);

// Animations Registration
Vue.component('loading-animation', LoadingAnimation);

// Modals Registration
Vue.component('modal', Modal);
Vue.component('delete-modal', DeleteModal);
Vue.component('confirm-modal', ConfirmModal);

// Forms Registration
Vue.component('c-form', CustomForm);

// Form Inputs Registration
Vue.component('validated-input', ValidatedInput);
Vue.component('validated-text-area', ValidatedTextArea);
Vue.component('validated-file-input', ValidatedFileInput);
Vue.component('validated-select', ValidatedSelect);
Vue.component('validated-ajax-select', ValidatedAjaxSelect);
Vue.component('validated-check-box', ValidatedCheckBox);

// Validations
Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);
